<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                  <b-row>
                    <b-col xl="6" lg="6" sm="12">
                        <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                    {{$t('org_pro.organization')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    id="org_id"
                                    :options="orgList"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    v-model="sector.org_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback d-block">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Sector Name(En)" vid="sector_name" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="sector_name"
                            slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{$t('research_manage.sector_name')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="sector_name"
                            v-model="sector.sector_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Sector Name(Bn)" vid="sector_name_bn" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="sector_name_bn"
                            slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{$t('research_manage.sector_name_bn')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="sector_name_bn"
                            v-model="sector.sector_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Sector Description(En)" vid="sector_description">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="sector_description"
                            slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{$t('research_manage.sector_description_en')}}
                            </template>
                            <b-form-textarea
                            id="sector_description"
                            v-model="sector.sector_description"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Sector Description(Bn)" vid="sector_description_bn">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="sector_description_bn"
                            slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{$t('research_manage.sector_description_bn')}}
                            </template>
                            <b-form-textarea
                            id="sector_description_bn"
                            v-model="sector.sector_description_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                  </b-row>
                  <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                      <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                      &nbsp;
                      <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                  </div>
                </b-form>
              </ValidationObserver>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '../../../../../../config/api_config'
import { sectorInfoStore, sectorInfoUpdate } from '../../../api/routes'
import researchTestingModal from '@/mixins/research-testing-modal'
export default {
  name: 'FormLayout',
  props: ['id'],
  mixins: [researchTestingModal],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        sector: {
            org_id: 0,
            project_id: '',
            sub_project_id: '',
            sector_code: '',
            sector_name: '',
            sector_name_bn: '',
            sector_description: '',
            sector_description_bn: ''
        },
        subProjectList: []
    }
  },
  computed: {
    orgList: function () {
      const objectData = this.$store.state.commonObj.organizationProfileList.filter(el => el.status === 0)
      return objectData.map(div => {
            return { value: div.value, text: this.$i18n.locale === 'en' ? div.text_en : div.text_bn }
          })
    },
    projectList: function () {
      const objectData = this.$store.state.AgriResearch.commonObj.projectList.filter(el => el.status === 0)
      return objectData.map(project => {
            return { value: project.value, text: this.$i18n.locale === 'en' ? project.text_en : project.text_bn }
          })
    }
    // subProjectList: function () {
    //   const objectData = this.$store.state.AgriResearch.commonObj.subProjectList.filter(el => el.status === 0)
    //   return objectData.map(subProject => {
    //         return { value: subProject.value, text: this.$i18n.locale === 'en' ? subProject.text_en : subProject.text_bn }
    //       })
    // }
  },
  watch: {
      'sector.project_id': function () {
          this.getSubPriojectList()
      }
  },
  created () {
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.sector.org_id = this.$store.state.Auth.authUser.org_id
      // if (this.$store.state.Auth.authUser.is_org_admin !== 1) {
      //   this.search.coordinator_id = this.$store.state.Auth.authUser.user_id
      // }
    }
    if (this.id) {
        const tmp = this.getProjectData()
        // Object.freeze(tmp)
        this.sector = tmp
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    getSubPriojectList () {
        const subProjectList = this.$store.state.AgriResearch.commonObj.subProjectList.filter(el => el.status === 0 && el.project_info_id === this.sector.project_id)
        this.subProjectList = subProjectList.map(subProject => {
            return { value: subProject.value, text: this.$i18n.locale === 'en' ? subProject.text_en : subProject.text_bn }
          })
    },
    getProjectData () {
      const tmpData = this.$store.state.list.find(project => project.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.id) {
        result = await RestApi.putData(agriResearchServiceBaseUrl, `${sectorInfoUpdate}/${this.id}`, this.sector)
      } else {
        result = await RestApi.postData(agriResearchServiceBaseUrl, sectorInfoStore, this.sector)
      }
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    }
  }
}
</script>
