
<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('research_manage.sector_info') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
            <b-col lg="6" md="5" sm="12" xs="12">
              <b-form-group
                  class="row"
                  label-cols-sm="3"
                  :label="$t('globalTrans.organization')"
                  label-for="org_id"
              >
                  <b-form-select
                      plain
                      id="org_id"
                      :options="orgList"
                      v-model="search.org_id"
                      placeholder=""
                  >
                  <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
              </b-form-group>
          </b-col>
          <!-- <b-col lg="5" md="5" sm="12" xs="12">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('research_manage.sector')"
                  label-for="name"
              >
                  <b-form-input
                      id="name"
                      v-model="search.name"
                      placeholder=""
                  ></b-form-input>
              </b-form-group>
          </b-col> -->
           <b-col lg="5" md="5" sm="12" xs="12">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('research_manage.sector')"
                    label-for="project_info_id"
                >
                    <b-form-select
                        plain
                        id="sector_id"
                        :options="sectorList"
                        v-model="search.sector_id"
                        placeholder=""
                    >
                    <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
          <b-col lg="2" md="2" sm="12" xs="12">
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('research_manage.sector_info') + ' ' + $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="bg-primary"  bordered hover :items="datas" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(lab_no)="data">
                      <span class="capitalize">{{ data.item.lab_no }}</span>
                    </template>
                    <template v-slot:cell(org)="data">
                      <span class="capitalize">{{  orgList.find(e=>e.value===data.item.org_id).text }}</span>
                    </template>
                    <template v-slot:cell(org_id)="data">
                      <span class="capitalize">{{ getOrgName(data.item.org_id) }}</span>
                    </template>
                    <template v-slot:cell(project)="data">
                      <span class="capitalize">{{ projectList.find(e=>e.value===data.item.project_id).text }}</span>
                    </template>
                    <template v-slot:cell(sub_project)="data">
                      <span class="capitalize">{{ subProjectList.find(e=>e.value===data.item.sub_project_id).text }}</span>
                    </template>
                    <template v-slot:cell(sector_name)="data">
                      {{ data.item.sector_name }}
                    </template>
                    <template v-slot:cell(sector_name_bn)="data">
                      {{ data.item.sector_name_bn }}
                    </template>
                    <template v-slot:cell(sector_descripton)="data">
                      <span class="capitalize">{{ data.item.sector_descripton }}</span>
                    </template>
                    <template v-slot:cell(sector_descripton_bn)="data">
                      <span class="capitalize">{{ data.item.sector_descripton_bn }}</span>
                    </template>
                    <template v-slot:cell(sector_code)="data">
                      {{ data.item.sector_code }}
                    </template>
                    <template v-slot:cell(status)="data">
                      <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                      <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button class="mr-1" title="View Details" v-b-modal.modal-detail variant=" iq-bg-success mr-1" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                      <b-button title="Edit" v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button :variant="data.item.status === 1 ? ' iq-bg-success border' : '  iq-bg-danger border'" size="sm" @click="remove(data.item)">
                        <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                      </b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
    <b-modal id="modal-detail" size="lg" :title="$t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <DetailModal :item="itemDetail"/>
    </b-modal>
  </b-container>
</template>
<script>
import FormV from './Form.vue'
import DetailModal from './DetailModal.vue'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { sectorInfoList, sectorInfoToggleStatus } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'
import { core } from '@/config/pluginInit'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    FormV, DetailModal
  },
  data () {
    return {
        itemDetail: {},
        datas: [],
        sectorList: [],
        search: {
          org_id: 0,
          sector_id: 0,
          name: ''
        }
    }
  },
    computed: {
        ...mapGetters({
            authUser: 'Auth/authUser'
        }),
        projectList: function () {
            const objectData = this.$store.state.AgriResearch.commonObj.projectList.filter(el => el.status === 0)
            return objectData.map(project => {
                    return { value: project.value, text: this.$i18n.locale === 'en' ? project.text_en : project.text_bn }
                })
        },
        subProjectList: function () {
            const objectData = this.$store.state.AgriResearch.commonObj.subProjectList.filter(el => el.status === 0)
            return objectData.map(project => {
                    return { value: project.value, text: this.$i18n.locale === 'en' ? project.text_en : project.text_bn }
                })
        },
        orgList: function () {
            const objectData = this.$store.state.commonObj.organizationProfileList.filter(el => el.status === 0)
            return objectData.map(item => {
                    return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
                })
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('research_manage.sector_info') + ' ' + this.$t('globalTrans.insert') : this.$t('research_manage.sector_info') + ' ' + this.$t('globalTrans.modify')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('globalTrans.organization'), class: 'text-left' },
                { label: this.$t('research_manage.sector_name'), class: 'text-left' },
                { label: this.$t('globalTrans.description'), class: 'text-left' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                    { key: 'index' },
                    { key: 'org_id' },
                    { key: 'sector_name_bn' },
                    { key: 'sector_description_bn' },
                    { key: 'status' },
                    { key: 'action' }
                ]
            } else {
                keys = [
                    { key: 'index' },
                    { key: 'org_id' },
                    { key: 'sector_name' },
                    { key: 'sector_description' },
                    { key: 'status' },
                    { key: 'action' }
                ]
            }
            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
  async created () {
    // this.search = Object.assign({}, this.search, {
    //   org_id: this.authUser.org_id
    // })
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.search.org_id = this.$store.state.Auth.authUser.org_id
      // if (this.$store.state.Auth.authUser.is_org_admin !== 1) {
      //   this.search.coordinator_id = this.$store.state.Auth.authUser.user_id
      // }
    }
    this.loadData()
  },
  watch: {
      'search.org_id': function (newValue, oldValue) {
          if (newValue !== oldValue) {
              this.search.sector_id = 0
              this.sectorList = this.getSector(newValue)
          } else {
              this.sectorList = []
          }
      }
  },
  mounted () {
    core.index()
    // if (this.authUser.role_id === 0) {
    //   this.search = Object.assign({}, this.search)
    // }
    // this.loadData()
  },
  methods: {
    getSector (orgId) {
        if (this.search.org_id) {
            const area = this.$store.state.AgriResearch.commonObj.sectorList.filter(item => item.status === 1)
            if (orgId) {
            return area.filter(item => item.org_id === parseInt(orgId))
            }
            return area
        }
    },
    getOrgName (id) {
        const Obj = this.$store.state.commonObj.organizationProfileList.find(item => item.value === parseInt(id))
        if (Obj) {
            if (this.$i18n.locale === 'en') {
                return Obj.text_en
            } else {
                return Obj.text_bn
            }
        } else {
                return ''
        }
    },
    details (item) {
      this.itemDetail = item
    },
    async searchData () {
      this.loadData()
    },
    getResearchType (data) {
       return data.map(d => {
          return this.options.find((e) => e.value === parseInt(d)).text
        }).toString()
    },
    remove (item) {
      this.changeStatus(agriResearchServiceBaseUrl, sectorInfoToggleStatus, item, 'agri_research', 'sectorList')
    },
    loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(agriResearchServiceBaseUrl, sectorInfoList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', response.data.data)
            this.paginationData(response.data)
            const allData = this.$store.state.list
            this.datas = JSON.parse(JSON.stringify(allData))
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    dataList (data) {
      const listData = data
      return listData
    }
  }
}
</script>
